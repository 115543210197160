import axios, { AxiosResponse } from 'axios';

import {
  AxiosRequest,
  ErrorResponse,
  handleError,
} from '@asa/asasqt-microfrontend-components/dist/common/api/axios';
import {
  PORTAL_ROUTES,
  PORTAL_ROUTES_NAMES,
} from '@asa/asasqt-microfrontend-components/dist/common/api/myAsaSqt/portal';
import { UserSettings } from '@asa/asasqt-microfrontend-components/dist/common/types';
import {
  AuthorizationRequest,
  LoginRequest,
  LogoutResponse,
  UserInfoRequest,
  UserInfoResponse,
} from '@asa/asasqt-microfrontend-components/dist/common/types/shared/auth';

import { ILIAS_ROUTES, ILIAS_ROUTES_NAMES } from '@/core/common/api/ilias';

import { TARGET_ILIAS_APP } from '../utils/auth.const';

export const userLogin = async ({
  credentials,
}: LoginRequest): Promise<ErrorResponse | AxiosResponse<any, any>> => {
  try {
    const response = await axios.post(ILIAS_ROUTES[ILIAS_ROUTES_NAMES.LOGIN], {
      ...credentials,
      targetIliasApplication: TARGET_ILIAS_APP,
    });
    return response;
  } catch (err) {
    return handleError(err);
  }
};

export const LogoutUser = async ({
  axiosIntercept,
}: AxiosRequest): Promise<
  LogoutResponse | ErrorResponse | AxiosResponse<any, any>
> => {
  try {
    const response = await axiosIntercept.delete(
      ILIAS_ROUTES[ILIAS_ROUTES_NAMES.LOGIN]
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const getAuthorization = async ({
  user24Services,
  axiosIntercept,
}: AuthorizationRequest): Promise<ErrorResponse | AxiosResponse<any, any>> => {
  try {
    const response = await axiosIntercept.post(
      ILIAS_ROUTES[ILIAS_ROUTES_NAMES.AUTHORIZATION],
      {
        user24Services,
      }
    );
    return response;
  } catch (err) {
    return handleError(err);
  }
};

export const getUserInfo = async ({
  axiosIntercept,
}: UserInfoRequest): Promise<
  UserInfoResponse | ErrorResponse | AxiosResponse<any, any>
> => {
  try {
    const response = await axiosIntercept.get(
      ILIAS_ROUTES[ILIAS_ROUTES_NAMES.USER_INFO]
    );
    return response;
  } catch (err) {
    return handleError(err);
  }
};

export const getRememberMe = async ({
  axiosIntercept,
}: AxiosRequest): Promise<ErrorResponse | AxiosResponse<any, any>> => {
  try {
    const response = await axiosIntercept.get(
      `${
        ILIAS_ROUTES[ILIAS_ROUTES_NAMES.REMEMBER_ME]
      }?targetIliasApplication=${TARGET_ILIAS_APP}`
    );
    return response;
  } catch (err) {
    return handleError(err);
  }
};

export const getUserSettings = async ({
  axiosIntercept,
}: AxiosRequest): Promise<
  UserSettings | ErrorResponse | AxiosResponse<any, any>
> => {
  try {
    const response = await axiosIntercept.get(
      PORTAL_ROUTES[PORTAL_ROUTES_NAMES.USER_SETTINGS]
    );
    return response;
  } catch (error) {
    return handleError(error);
  }
};

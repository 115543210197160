import '@module-federation/nextjs-mf/src/include-defaults';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Suspense } from 'react';
import React from 'react';
import { RecoilRoot } from 'recoil';

import { ChakraProvider } from '@chakra-ui/react';

import useMatomo, {
  SiteIds,
} from '@asa/asasqt-microfrontend-components/dist/hooks/useMatomo';
import { Loader } from '@asa/asasqt-microfrontend-components/dist/layout/animate/Loader';
import { Viewport } from '@asa/asasqt-microfrontend-components/dist/layout/ui/Viewport';

import { Providers } from '@/Providers';
import theme from '@/theme';

import favicon from '../../public/favicon.ico';

const App = ({ Component, pageProps }: AppProps) => {
  const siteIds: SiteIds = {
    dev: 16,
    qs: 18,
    demo: 20,
    prod: 6,
  };

  useMatomo({ siteIds, excludedQueryParams: ['vin', 'q'] });

  return (
    <>
      <Head>
        <title>ASA SQT</title>
        <link rel="icon" href={favicon.src} />
      </Head>
      <Suspense fallback={<Loader />}>
        <ChakraProvider
          theme={{
            ...theme,
          }}
        >
          <RecoilRoot>
            <Providers>
              <Viewport>
                <Component {...pageProps} />
              </Viewport>
            </Providers>
          </RecoilRoot>
        </ChakraProvider>
      </Suspense>
    </>
  );
};
export default App;

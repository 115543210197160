import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { useRouter } from 'next/router';
import React, { useContext, useRef } from 'react';
import { useRecoilState } from 'recoil';

import { AxiosStatusCode } from '@asa/asasqt-microfrontend-components/dist/common/api/axios/utils/types';

import { URLRoutes } from '@/core/common/helpers';

import { ILIAS_ROUTES, ILIAS_ROUTES_NAMES } from '../api/ilias';
import useLogout from '../hooks/useLogout';
import useRefreshToken from '../hooks/useRefreshToken';
import { languageAtom } from '../language/core/language.recoil';
import { tokenAtom } from '../recoil';
import { IProps } from './types';

export const AxiosContext = React.createContext({} as AxiosInstance);

export function useAxiosIntercept() {
  return useContext(AxiosContext);
}

const MAX_RETRY = 2;

export function AxiosContextProvider({ children }: IProps) {
  const { isTokenRefreshed, refreshToken, setTokenRefreshed } =
    useRefreshToken();
  const { initiateLogout } = useLogout();
  const [token] = useRecoilState(tokenAtom);
  const [language, setLanguage] = useRecoilState(languageAtom);

  const router = useRouter();
  const axiosToken = token;
  const isRefreshing = useRef<boolean>(false);
  const refreshPromise = useRef<Promise<void> | null>(null);

  const axiosIntercept = axios.create({
    timeout: 35000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Accept-Language': language,
    },
  });

  axiosIntercept.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      let refreshedToken = '';
      if (axiosToken) {
        const jwt = JSON.parse(atob(axiosToken.split('.')[1]));
        const axiosTokenExpirationDate = jwt && jwt.exp && jwt.exp * 1000;
        if (
          config.url !== ILIAS_ROUTES[ILIAS_ROUTES_NAMES.AUTHORIZATION] &&
          axiosTokenExpirationDate - 60000 < Date.now() &&
          !isRefreshing.current
        ) {
          isRefreshing.current = true;
          const refresh = refreshToken();
          refreshPromise.current = refresh;
          refreshedToken = await refresh;

          refreshPromise.current = null;
          isRefreshing.current = false;
        }
        if (
          axiosToken &&
          config.url !== ILIAS_ROUTES[ILIAS_ROUTES_NAMES.AUTHORIZATION] &&
          !config.headers?.Authorization
        ) {
          if (isTokenRefreshed) {
            // Use the refreshed token
            config.headers['Authorization'] = `Bearer ${
              refreshedToken || axiosToken
            }`;
            setTokenRefreshed(false); // Reset the tokenRefreshed flag
          } else {
            // Use the original token
            config.headers['Authorization'] = `Bearer ${axiosToken}`;
          }
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosIntercept.interceptors.response.use(
    (response: AxiosResponse) => {
      if (response.data?.language) {
        setLanguage(response.data?.language);
      }
      return response;
    },
    async (error) => {
      const config = error.config;

      if (config.handled503) {
        return Promise.reject(error);
      }

      // Handling 503 Service Unavailable
      if (error?.response?.status === 503) {
        config.retry503Count = (config.retry503Count || 0) + 1;
        if (config.retry503Count <= 3) {
          return axiosIntercept(config);
        } else {
          console.error('Handling 503: Redirecting after 3 retries');
          return Promise.reject(
            new Error('503 Service Unavailable - handled after retries')
          );
        }
      }

      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        if (config._retryCount === undefined) {
          // Creating a _retryCount property in the config object and initializing it to 1
          config._retryCount = 1;
        }
        if (config._retryCount < MAX_RETRY) {
          // If it's already failed once, increment the _retryCount
          config._retryCount++;
        } else {
          return Promise.reject(error);
        }
      }

      if (config._retryCount <= MAX_RETRY) {
        // Retry request, if _retryCount is less than or equal to MAX_RETRY
        return axiosIntercept(config);
      }

      if (error?.response?.status === AxiosStatusCode.ERR_401) {
        if (
          error?.config?.url === ILIAS_ROUTES[ILIAS_ROUTES_NAMES.AUTHORIZATION]
        ) {
          initiateLogout();
        } else if (
          isRefreshing.current &&
          refreshPromise.current !== null &&
          !error?.config['X-Retry']
        ) {
          const refreshedToken = await refreshPromise.current;
          const newConfig = error?.config;
          newConfig.headers.Authorization = `Bearer ${refreshedToken}`;
          newConfig.headers['X-Retry'] = true;
          return axiosIntercept.request(newConfig);
        } else if (!router.pathname.includes(URLRoutes.auth)) {
          initiateLogout(true);
        }
      }

      return Promise.reject(error);
    }
  );

  return (
    <AxiosContext.Provider value={axiosIntercept}>
      {children}
    </AxiosContext.Provider>
  );
}

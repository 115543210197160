import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { MouseEventHandler, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { UrlObject } from 'url';

import {
  Box,
  Divider,
  Flex,
  Stack,
  StackProps,
  Tooltip,
} from '@chakra-ui/react';

import { CarIcon } from '@asa/asasqt-microfrontend-components/dist/Icons/Car';
import { HomeIcon } from '@asa/asasqt-microfrontend-components/dist/Icons/Home';
import { ManageAccountIcon } from '@asa/asasqt-microfrontend-components/dist/Icons/ManageAccount';
import { QuotationIcon } from '@asa/asasqt-microfrontend-components/dist/Icons/Quotation';
import { SettingsIcon } from '@asa/asasqt-microfrontend-components/dist/Icons/Settings';
import { MdOutlineNotificationsNone } from '@asa/asasqt-microfrontend-components/dist/Icons/lib/MdOutlineNotificationsNone';
import { useHasServiceAppRole } from '@asa/asasqt-microfrontend-components/dist/hooks/useHasServiceAppRole';
import { useLayoutContext } from '@asa/asasqt-microfrontend-components/dist/layout/ui/View';
import { Icon } from '@asa/asasqt-microfrontend-components/dist/typography/Icons';
import { Text } from '@asa/asasqt-microfrontend-components/dist/typography/Text';

import { tokenAtom } from '@/core/auth/core/auth.recoil';
import {
  URLRoutes,
  useMappedURLRoute,
  useURLRoutesLang,
} from '@/core/common/helpers';
import { useUnreadNotificationCount } from '@/core/common/hooks/useNotificationCount';

export type MenuProps = StackProps & {
  isDrawer?: boolean;
};

export const MainMenu = ({ isDrawer, ...rest }: MenuProps) => {
  const router = useRouter();
  const { navOnClose } = useLayoutContext();
  const T = useURLRoutesLang();
  const URLRoutesT = useMappedURLRoute();
  const hasServiceAppRole = useHasServiceAppRole();
  const [token] = useRecoilState(tokenAtom);

  const pathSplit = router.asPath.split('/');
  const activePage = pathSplit[1];
  const isMenuSelection =
    activePage === URLRoutes.menuList &&
    pathSplit.length > 3 &&
    pathSplit[3] === 'details';

  const { unreadCount, unreadCountShort, fetchCount } =
    useUnreadNotificationCount();

  // Update unread notification cont every 1 minute
  useEffect(() => {
    if (!hasServiceAppRole) {
      return;
    }

    const intervalId = setInterval(fetchCount, 60 * 1000);
    fetchCount();

    // clean up interval on unmount
    return () => clearInterval(intervalId);
  }, [token]);

  const MenuItem = ({
    onClick,
    href,
    isActive,
    label,
    icon,
    disabled,
    testId,
    counter,
    counterShort,
  }: {
    onClick?: MouseEventHandler | undefined;
    href: string | UrlObject;
    isActive?: boolean;
    label: string;
    icon: TODO;
    disabled?: boolean;
    testId: string;
    counter?: number;
    counterShort?: string;
  }) => {
    const iconProps = { h: 6, w: 6 };
    const activeIconProps = { ...iconProps, color: 'brand.600' };
    const drawerIconProps = { ...iconProps, color: 'white' };

    return (
      <Link
        href={href}
        onClick={onClick}
        style={disabled ? { pointerEvents: 'none' } : {}}
        passHref
        data-testid={testId}
      >
        {!isDrawer ? (
          <Box
            width={16}
            maxWidth={16}
            height="100%"
            justifyContent="center"
            display="flex"
          >
            <Tooltip label={label} position="relative">
              <Icon
                icon={icon}
                fontSize={24}
                right={'6px'}
                color={isActive ? 'brand.600' : 'gray.999'}
                top={'8px'}
                position={'relative'}
                iconProps={isActive ? activeIconProps : iconProps}
              />
            </Tooltip>
            {!!counter && counter > 0 && (
              <Box width={0} overflow="visible">
                <Text
                  position="relative"
                  width="17px"
                  height="17px"
                  lineHeight="17px"
                  borderRadius="8px"
                  alignItems="center"
                  justifyContent="center"
                  top="-2px"
                  right="10px"
                  fontSize="9px"
                  color={'gray.50'}
                  backgroundColor={'brand.600'}
                  textAlign="center"
                >
                  {counterShort}
                </Text>
              </Box>
            )}
            {isActive && (
              <Divider
                backgroundColor="brand.600"
                opacity={1}
                height={0.5}
                width={16}
                borderBottomWidth={0}
                bottom={'calc(0.125rem * -0.1)'}
                position="absolute"
              />
            )}
          </Box>
        ) : (
          <Flex onClick={navOnClose} alignItems="center">
            <Icon icon={icon} iconProps={drawerIconProps} />
            <Text ml={3}>{label}</Text>
            {!!counter && counter > 0 && (
              <Text
                width="17px"
                height="17px"
                lineHeight="17px"
                borderRadius="8px"
                fontSize="9px"
                color={'gray.50'}
                backgroundColor={'brand.600'}
                textAlign="center"
                ml={2}
              >
                {counterShort}
              </Text>
            )}
          </Flex>
        )}
      </Link>
    );
  };

  return (
    <Stack
      direction={isDrawer ? 'column' : 'row'}
      alignContent={'center'}
      flexGrow={isDrawer ? '1' : 'inherit'}
      justifyContent={!isDrawer ? 'center' : 'inherit'}
      marginTop={isDrawer ? 'auto' : 'inherit'}
      marginLeft={!isDrawer ? 'auto' : 'inherit'}
      {...rest}
    >
      <MenuItem
        label={URLRoutesT[URLRoutes.dashboard]}
        href={`/${URLRoutes.dashboard}`}
        icon={HomeIcon}
        isActive={activePage === URLRoutes.dashboard || isMenuSelection}
        testId={'mainMenu_link_home'}
      />
      <MenuItem
        label={T.VHC}
        href={`/${URLRoutes.vhcList}`}
        icon={CarIcon}
        isActive={
          activePage === URLRoutes.vhcList ||
          activePage === URLRoutes.vehicleHealthCheck
        }
        testId={'mainMenu_link_vhc'}
      />
      <MenuItem
        label={T.quotation}
        href={`/${URLRoutes.menuList}`}
        icon={QuotationIcon}
        isActive={activePage === URLRoutes.menuList && !isMenuSelection}
        testId={'mainMenu_link_quotation'}
      />
      <MenuItem
        label={T.customerVehicles}
        href={`/${URLRoutes.customerList}`}
        icon={ManageAccountIcon}
        isActive={activePage === URLRoutes.customerList}
        testId={'mainMenu_link_customer'}
      />
      <MenuItem
        label={URLRoutesT[URLRoutes.settings]}
        href={`/${URLRoutes.settings}`}
        icon={SettingsIcon}
        isActive={activePage === URLRoutes.settings}
        testId={'mainMenu_link_settings'}
      />
      {hasServiceAppRole && (
        <MenuItem
          label={URLRoutesT[URLRoutes.notifications]}
          href={`/${URLRoutes.notifications}`}
          icon={MdOutlineNotificationsNone}
          isActive={activePage === URLRoutes.notifications}
          testId={'mainMenu_link_notifications'}
          counter={unreadCount}
          counterShort={unreadCountShort}
        />
      )}
    </Stack>
  );
};
